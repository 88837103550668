import * as Api from "../network/api";
import {Medication, User } from '../models/user';
import '../styles/DatabasePage.css';
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
// import exercisesList from '../localdata/exercises'; // Import the exercises list
import medicationsList from '../localdata/products.json';

import BodyHighlighterDiagram from "./BodyHighlighter";
import { Button } from "react-bootstrap";
import { IExerciseData } from "react-body-highlighter";
import Select from 'react-select';
import colorPalette from "../utils/colors";
import { DBMedication } from "../models/medication";
import { DBCondition } from "../models/condition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faHeartPulse, faPills } from "@fortawesome/free-solid-svg-icons";

interface DatabasePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user: User) => void
}

interface Requirement {
    name:string,
    description:string,
    id:string,
}

interface Symptom {
    label:string,
    description:string,
    id:string,
}

interface Exercise {
    name: string;
    force: string;
    level: string;
    mechanic: string;
    equipment: string;
    primaryMuscles: string[];
    secondaryMuscles: string[];
    instructions: string[];
    category: string;
    images: string[];
    conditionsBlackList: string[];
    physicalRequirements:Requirement[];
    id: string;
}

const DatabasePageLoggedInView = ({ user, onLogoutSuccessful, onPlanUpdated }: DatabasePageLoggedInProps) => {
    const [loading, setLoading] = useState(true);
    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [forceFilter, setForceFilter] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string>("");
    const [musclesFilter, setMusclesFilter] = useState<string>("");
    const [conditionFilter, setConditionFilter] = useState<string>("");

    const [filteredMedications, setFilteredMedications] = useState<DBMedication[]>([]);
    const [filteredConditions, setFilteredConditions] = useState<DBCondition[]>([]);

    const [exercises, setExercises] = useState<Exercise[]>([]);
    const [medications, setMedications] = useState<DBMedication[]>([]);
    const [conditions, setConditions] = useState<DBCondition[]>([]);

    const [showPopup, setShowPopup] = useState(false);

    const [selectedRequirement, setSelectedRequirement] = useState<Requirement>({id:'', name:'', description:''});
    const [selectedSymptom, setSelectedSymptom] = useState<Symptom>({id:'', label:'', description:''});

    const [selectedTab, setSelectedTab] = useState('exercises');

    const [isEditing, setIsEditing] = useState(false); // Flag to check if editing
    const [editIndex, setEditIndex] = useState<number | null>(null); // Store index of the exercise being edited
    const [currentStep, setCurrentStep] = useState(0); // Track current step in multi-step process
    const [newExercise, setNewExercise] = useState<Exercise>({
        name: '',
        force: '',
        level: '',
        mechanic: '',
        equipment: '',
        primaryMuscles: [],
        secondaryMuscles: [],
        instructions: [],
        category: '',
        images: [],
        conditionsBlackList: [],
        physicalRequirements:[],
        id: Math.random().toString(36).substr(2, 9)
    });
    const [newMedication, setNewMedication] = useState<DBMedication>({
        name:'',
        medId:''
    });
    const [newCondition, setNewCondition] = useState<DBCondition>({
        conditionId:'',
        conditionName:'',
        description:'',
        symptoms:[]
    });

    const [selectedMuscles, setSelectedMuscles] = useState<IExerciseData[]>([{muscles:['abductors'], name:''}]);

    const requirementOptions = [
        { id: '0', label: 'Be Flexible' },
        { id: '1', label: 'Endure Long Cardiovascular Activity' },
        { id: '2', label: 'Reach Zone 4' },
        { id: '3', label: 'Maintain Balance' },
        { id: '4', label: 'Use Their Bodyweight' },
    ];

    const symptomOptions = [
        { id: 's_0', label: 'Dizziness', description:''},
        { id: 's_1', label: 'Fatigue', description:''},
      ];

    const hashStringToId = (name: string) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            const char = name.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32-bit integer
        }
        return Math.abs(hash).toString(36).substr(0, 9); // Convert to base-36 and limit to 9 characters
    };

    useEffect(() => {
        getExercises();
        getMedications();
        getConditions();
        // // FOR ADDING EXERCISES TO DATBASE
        // for (const m of medicationsList){
        //     const medicationToAdd:DBMedication = {
        //         name: m.DrugName,
        //         medId: m.ProductNo,
        //     }
        //     uploadMedication(medicationToAdd);
        // }
        // alert('done')

        // Simulate data loading
        setTimeout(() => {
            setLoading(false);
            setFilteredExercises(exercises as Exercise[]); // Type assertion to cast exercises as Exercise[]
            setFilteredMedications(medications as DBMedication[])
            setFilteredConditions(conditions as DBCondition[])
        }, 1000); // Simulating a network request delay
    }, []);

    async function uploadExercise(exerciseToAdd:Exercise){
        await Api.createExercise(exerciseToAdd);
    }

    async function uploadMedication(medicationToAdd:DBMedication){
        await Api.createMedication(medicationToAdd);
    }

    async function uploadCondition(conditionToAdd:DBCondition){
        await Api.createCondition(conditionToAdd);
    }

    async function getExercises(){
        const response = await Api.getExercises().then(res=>{
            setExercises(res);
            setFilteredExercises(res)
            console.log(res)
        });
        
    }

    async function getMedications(){
        const response = await Api.getMedications().then(res=>{
            setMedications(res);
            setFilteredMedications(res);
            console.log(res)
        });
    }

    async function getConditions(){
        const response = await Api.getConditions().then(res=>{
            setConditions(res);
            setFilteredConditions(res);
            console.log(res)
        });
    }

    const handleSearch = (value: string = searchTerm) => {
        // Use 'value' directly instead of 'searchTerm' since setSearchTerm is async
        setSearchTerm(value);
    
        if (selectedTab === 'exercises') {
            const filtered = exercises.length > 0 ? exercises.filter((exercise: Exercise) =>
                exercise.name.toLowerCase().trim().includes(value.toLowerCase().trim()) &&  // Use 'value' instead of 'searchTerm'
                (forceFilter ? exercise.force === forceFilter : true) &&
                (levelFilter ? exercise.level === levelFilter : true) &&
                (musclesFilter ? (exercise.primaryMuscles as string[]).includes(musclesFilter) : true) &&
                (conditionFilter ? (exercise.conditionsBlackList as string[]).includes(conditionFilter) : true)
            ) : [];
    
            setFilteredExercises(filtered);
        } else if (selectedTab === 'medications') {
            const filtered = medications.length > 0 ? medications.filter((medication: DBMedication) => 
                medication.name.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredMedications(filtered);
        } else if (selectedTab === 'conditions') {
            const filtered = conditions.length > 0 ? conditions.filter((condition: DBCondition) => 
                condition.conditionName.toLowerCase().trim().includes(value.toLowerCase().trim())
            ) : [];
    
            setFilteredConditions(filtered);
        }
    };
    

    const handleRequirementChange = (selectedOption:any) => {
        setSelectedRequirement(selectedOption ? {
            description:selectedOption.label,
            name:'',
            id:''
        } : {
            description:'',
            name:'',
            id:''
        });
    };

    const handleSymptomChange = (selectedOption: any) => {
    setSelectedSymptom(selectedOption); // selectedOption will be an object like { value: 'dizziness', label: 'Dizziness' }
    };

    const removeExerciseRequirement = (requirement:Requirement) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedRequirements = newExercise.physicalRequirements.filter(
            (req) => req !== requirement
        );

        // Update the state with the new array of physical requirements
        setNewExercise({
            ...newExercise, // Spread the existing newExercise object
            physicalRequirements: updatedRequirements, // Update the physicalRequirements array
        });
    }

    const removeConditionSymptom = (symptom:Symptom) =>{
        // Create a new array that filters out the requirement to be removed
        const updatedSymptoms = newCondition.symptoms.filter(
            (s) => s !== symptom
        );

        // Update the state with the new array of physical requirements
        setNewCondition({
            ...newCondition, // Spread the existing newExercise object
            symptoms: updatedSymptoms, // Update the physicalRequirements array
        });
    }

    const showAddExercisePopup = () => {
        setIsEditing(false);  // Set to false when adding a new exercise
        setNewExercise({
            name: '',
            force: '',
            level: '',
            mechanic: '',
            equipment: '',
            primaryMuscles: [],
            secondaryMuscles: [],
            instructions: [],
            category: '',
            images: [],
            conditionsBlackList: [],
            physicalRequirements:[],
            id: Math.random().toString(36).substr(2, 9) // Generate new random ID
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddMedicationPopup = () => {
        setIsEditing(false);  // Set to false when adding a new medication
        setNewMedication({
            name:'',
            medId:''
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const showAddConditionPopup = () => {
        setIsEditing(false);  // Set to false when adding a new condition
        setNewCondition({
            conditionId:'',
            conditionName:'',
            description:'',
            symptoms:[]
        });
        setCurrentStep(0); // Start from step 0
        setShowPopup(true);
    };

    const editExercise = (index: number) => {
        const exerciseToEdit = filteredExercises[index];
        setNewExercise(exerciseToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editMedication = (index: number) => {
        const medicationToEdit = filteredMedications[index];
        setNewMedication(medicationToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const editCondition = (index: number) => {
        const conditionToEdit = filteredConditions[index];
        setNewCondition(conditionToEdit);  // Populate the popup with the exercise details
        setIsEditing(true);              // Set the flag to true for editing mode
        setEditIndex(index);             // Store the index of the exercise being edited
        setCurrentStep(0);               // Start from step 0 when editing
        setShowPopup(true);              // Show the popup
    };

    const handleAddRequirement = () => {
        // Create a copy of the current newExercise object
        const updatedExercise = {
            ...newExercise,
            physicalRequirements: [...newExercise.physicalRequirements, selectedRequirement],
        };
    
        // Update the state with the new object
        setNewExercise(updatedExercise);
    };
    
    const handleAddSymptom = () => {
        // Create a copy of the current newExercise object
        const updatedCondition = {
            ...newCondition,
            symptoms: [...newCondition.symptoms, selectedSymptom],
        };
    
        // Update the state with the new object
        setNewCondition(updatedCondition);
        console.log(newCondition)

    };

    const closePopup = () => {
        setShowPopup(false);
        setIsEditing(false);
        setEditIndex(null);
    };

    const handleExerciseChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewExercise({ ...newExercise, [name]: value });
    };

    const handleMedicationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewMedication({ ...newMedication, [name]: value });
    };

    const handleConditionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewCondition({ ...newCondition, [name]: value });
    };
    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 0){
            setCurrentStep(currentStep - 1);

        }
    };

    const handleSaveExercise = async () => {
        if (isEditing && editIndex !== null) {
            const updatedExercises = [...filteredExercises];
            updatedExercises[editIndex] = newExercise; // Replace the exercise at the current index
            setFilteredExercises(updatedExercises);
        } else {
            setFilteredExercises([newExercise, ...filteredExercises]);
        }
        await Api.createExercise(newExercise);
        closePopup();
    };

    const handleSaveMedication = async () => {
        if (isEditing && editIndex !== null) {
            const updatedMedications = [...filteredMedications];
            updatedMedications[editIndex] = newMedication; // Replace the exercise at the current index
            setFilteredMedications(updatedMedications);
        } else {
            setFilteredMedications([newMedication, ...filteredMedications]);
        }
        await Api.createMedication(newMedication);
        closePopup();
    };

    const handleSaveCondition = async () => {
        if (isEditing && editIndex !== null) {
            const updatedConditions = [...filteredConditions];
            updatedConditions[editIndex] = newCondition; // Replace the exercise at the current index
            setFilteredConditions(updatedConditions);
        } else {
            setFilteredConditions([newCondition, ...filteredConditions]);
        }
        await Api.createCondition(newCondition);
        closePopup();
    };

    const renderExerciseStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Exercise Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Exercise Name"
                            value={newExercise.name}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Force</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="force"
                            placeholder="Force (e.g., Push/Pull)"
                            value={newExercise.force}
                            onChange={handleExerciseChange}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Equipment and Level</label>
                        <label style={{marginTop:'20px'}}>Difficulty Level</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="level"
                            placeholder="Level (e.g., Beginner/Intermediate)"
                            value={newExercise.level}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Equipment Required</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="equipment"
                            placeholder="Equipment"
                            value={newExercise.equipment}
                            onChange={handleExerciseChange}
                        />
                    </>
                );
            case 2:
                return (
                    <>
                        <label className="step-title">Step 3: Muscles and Category</label>
                        <label style={{marginTop:'20px'}}>Category</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="category"
                            placeholder="Category"
                            value={newExercise.category}
                            onChange={handleExerciseChange}
                        />
                        <label style={{marginTop:'20px'}}>Muscle Groups Used</label>

                        <BodyHighlighterDiagram 
                            width={80} height={30} 
                            data={[]}
                            />

                    </>
                );
                case 3:
                    return (
                        <>
                            <label className="step-title">Step 4: Physical Requirements</label>
                            <label style={{marginTop:'20px'}}>{`A ${newExercise.name} requires the patient's ability to ...`}</label>
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
                            <Select
                                className="exercise-info-input"
                                placeholder="Requirement"
                                name="requirements"
                                value={requirementOptions.find(option => 
                                    newExercise.physicalRequirements.map((r) => r.id).includes(option.id)
                                )}                                
                                onChange={handleRequirementChange}
                                options={requirementOptions}
                                isClearable
                            />
                            <Button className="form-next-button" style={{marginTop:'20px', marginLeft:'5px', fontSize:'1vw'}} onClick={handleAddRequirement}>Add Requirement</Button>

                            </div>
                            {newExercise.physicalRequirements.map((requirement:Requirement)=>(
                                <div onClick={()=>removeExerciseRequirement(requirement)}>
                                    <label>{requirement.description}</label>
                                </div>
                            ))}

                            </>
                    );
            default:
                return null;
        }
    };

    const renderMedicationStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Medication Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="name"
                            placeholder="Medication Name"
                            value={newMedication.name}
                            onChange={handleMedicationChange}
                        />
                        <label style={{marginTop:'20px'}}>Medication ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="medId"
                            placeholder="Medication ID"
                            value={newMedication.medId}
                            onChange={handleMedicationChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const renderConditionStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <label className="step-title">Step 1: Basic Information</label>
                        <label style={{marginTop:'20px'}}>Condition Name</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="conditionName"
                            placeholder="Condition Name"
                            value={newCondition.conditionName}
                            onChange={handleConditionChange}
                        />
                        <label style={{marginTop:'20px'}}>Condition ID</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="conditionId"
                            placeholder="Condition ID"
                            value={newCondition.conditionId}
                            onChange={handleConditionChange}
                        />
                         <label style={{marginTop:'20px'}}>Condition Description</label>

                        <input
                            className="exercise-info-input"
                            type="text"
                            name="description"
                            placeholder="Condition Description"
                            value={newCondition.description}
                            onChange={handleConditionChange}
                        />
                    </>
                );
            case 1:
                return (
                    <>
                        <label className="step-title">Step 2: Symptoms</label>
                        <label style={{ marginTop: '20px' }}>{`List all the symptoms of ${newCondition.conditionName}`}</label>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Select
                            className="exercise-info-input"
                            placeholder="Symptoms"
                            name="symptoms"
                            value={symptomOptions.find(option => 
                                newCondition.symptoms.map((symptom) => symptom.id).includes(option.id)
                            )}
                            onChange={handleSymptomChange}
                            options={symptomOptions}
                            isClearable
                        />
                        <Button className="form-next-button" style={{ marginTop: '20px', marginLeft: '5px', fontSize: '1vw' }} onClick={handleAddSymptom}>
                            Add Symptom
                        </Button>
                        </div>

                        {/* Displaying selected symptoms */}
                        {newCondition.symptoms.map((s: Symptom) => (
                        <div key={s.id} onClick={() => removeConditionSymptom(s)}>
                            <label>{s.label}</label>
                        </div>
                        ))}
                    </>

                );
            default:
                return null;
        }
    };
    
    return (
        <div className="database-page-container">
            {loading ? (
                <Spinner text="Loading Database" />
            ) : showPopup ? (
                    <div className="card-enlarged" style={{ display: 'flex', flexDirection: 'column', color:'#454545', justifyContent:'flex-start'}}>
                        
                        {/* <h2>{isEditing ? "Edit Exercise" : "Add New Exercise"}</h2> */}
                        <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start', flexDirection:'column'}}>
                            {selectedTab === 'exercises' &&
                                renderExerciseStepContent()
                            }
                            {selectedTab === 'medications' &&
                                renderMedicationStepContent()  
                            }
                            {selectedTab === 'conditions' && 
                                renderConditionStepContent()
                            }
                        </div>
                        <div style={{width:'90%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:'9vh'}}>
                            {currentStep <= 0 ? 
                                <Button className="form-back-button" onClick={closePopup}>Close Without Saving</Button>
                            :
                                <Button className="form-back-button" onClick={handleBack}>Previous</Button>
                            }
                            {(selectedTab === 'exercises' && currentStep < 3) || (selectedTab === 'medications' && currentStep < 1) || (selectedTab === 'conditions' && currentStep < 2) ? 
                                <Button className="form-next-button" onClick={handleNext}>Next Step</Button>
                            :
                                selectedTab === 'exercises'?

                                <Button className="form-next-button" onClick={handleSaveExercise}>{isEditing ? "Update Exercise" : "Save Exercise"}</Button>
                                :
                                selectedTab === 'medications'?
                                <Button className="form-next-button" onClick={handleSaveMedication}>{isEditing ? "Update Medication" : "Save Medication"}</Button>
                                :
                                <Button className="form-next-button" onClick={handleSaveCondition}>{isEditing ? "Update Condition" : "Save Condition"}</Button>


                            }
                        </div>
                        
                        {/* <button className="popup-close-button" onClick={closePopup}>Close</button> */}
                    </div>
            ) : (
                <div>
                    <h1 className="database-page-header">VitovaDB</h1>

                    <div style={{display:'flex', width:'100%', height:'8vh', backgroundColor:'transparent', marginBottom:'2vh'}}>
                        <div className="tab" onClick={()=>setSelectedTab('exercises')} style={{backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'exercises'?'bold':'normal', fontSize:'1.25vw'}}>Exercises</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faDumbbell} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('medications')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}ee`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'medications'?'bold':'normal', fontSize:'1.25vw'}}>Medications</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faPills} color="white"/>

                        </div>
                        <div className="tab" onClick={()=>setSelectedTab('conditions')} style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', backgroundColor:`${colorPalette.steelBlue}`}}>
                            <label className="tab-label" style={{color:'#f1f1f1', fontWeight:selectedTab === 'conditions'?'bold':'normal', fontSize:'1.25vw'}}>Conditions</label>
                            <FontAwesomeIcon style={{marginLeft:'10px'}} icon={faHeartPulse} color="white"/>


                        </div>

                    </div>
                    {selectedTab === 'exercises' &&
                        <div>
                        {/* Search Bar */}
                        <input
                            type="text"
                            placeholder="Search exercises..."
                            value={searchTerm}
                            className="database-page-search-bar"
                            onChange={(e) => handleSearch(e.target.value)}
                        />

                        {/* Filters */}
                        <div className="database-page-filters">
                            <select value={forceFilter} className="database-page-filter-select" onChange={(e) => setForceFilter(e.target.value)}>
                                <option value="">All Forces</option>
                                <option value="pull">Pull</option>
                                <option value="push">Push</option>
                            </select>

                            <select value={levelFilter} className="database-page-filter-select" onChange={(e) => setLevelFilter(e.target.value)}>
                                <option value="">All Levels</option>
                                <option value="beginner">Beginner</option>
                                <option value="intermediate">Intermediate</option>
                                <option value="advanced">Advanced</option>
                            </select>

                            <select value={musclesFilter} className="database-page-filter-select" onChange={(e) => setMusclesFilter(e.target.value)}>
                                <option value="">All Muscle Groups</option>
                                <option value="quadriceps">Quads</option>
                                <option value="biceps">Biceps</option>
                                <option value="lats">Lats</option>
                                <option value="hamstring">Hamstrings</option>
                                <option value="abs">Abdominals</option>
                            </select>

                            <select value={conditionFilter} className="database-page-filter-select" onChange={(e) => setConditionFilter(e.target.value)}>
                                <option value="">No Conditions</option>
                                <option value="obesity">Obesity</option>
                                <option value="anxiety">Anxiety</option>
                                <option value="depression">Depression</option>
                            </select>

                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                                <button className="database-page-filter-button" onClick={showAddExercisePopup}>Add Exercise</button>
                                <button className="database-page-filter-button" onClick={()=>handleSearch()}>Apply Filters</button>
                            </div>
                        </div>

                        {/* Exercise List */}
                        <ul className="database-page-exercise-list">
                            {filteredExercises.slice(0, 25).map((exercise, index) => (
                                <li key={exercise.id} className="database-page-exercise-item" onClick={() => editExercise(index)}>
                                    <div className="exercise-card">
                                        {/* Display the exercise image if available */}
                                        {exercise.images.length > 0 ?
                                            <div className="exercise-card-image">
                                                <img     
                                                    src={`https://raw.githubusercontent.com/yuhonas/free-exercise-db/main/exercises/${exercise.images[0]}`} 
                                                    alt={exercise.name} 
                                                    />
                                            </div>
                                            :                                            
                                            <div className="exercise-card-image">
                                            <img     
                                                src={`https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930`} 
                                                alt={exercise.name} 
                                                />
                                            </div>
                                        }
                                        <div className="exercise-card-content">
                                            <h3 className="exercise-card-title">{exercise.name}</h3>
                                            <p><strong>Force:</strong> {exercise.force}</p>
                                            <p><strong>Level:</strong> {exercise.level}</p>
                                            <p><strong>Equipment:</strong> {exercise.equipment}</p>
                                            <p><strong>Primary Muscles:</strong> {exercise.primaryMuscles.join(', ')}</p>
                                            <p><strong>Category:</strong> {exercise.category}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'medications' &&
                        <div>
                            <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '6vh', width: '80%' }}
                                type="text"
                                placeholder="Search medications..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '10px', marginTop:'10px'}}>
                                <button
                                className="database-page-filter-button"
                                onClick={showAddMedicationPopup}
                                style={{ height: '6vh' }}
                                >
                                Add Medication
                                </button>
                            </div>
                            </div>

                        <label style={{marginLeft:'1vw', marginBottom:'1vw'}}>{`Number of results: ${filteredMedications.length}`}</label>

                        {/* MEdications List */}
                        <ul className="medication-list">
                            {filteredMedications.slice(0, 25).map((medication, index) => (
                                <li
                                key={medication._id || medication.medId}
                                className="medication-item"
                                onClick={() => editMedication(index)}
                                >
                                <div className="medication-card">
                                    <div className="medication-header">
                                    <h3>{medication.name}</h3>
                                    </div>
                                </div>
                                </li>
                            ))}
                        </ul>

                    </div>
                    }
                    {selectedTab === 'conditions' &&
                        <div>
                            <div style={{ display: 'flex', width: '100vw', alignItems: 'center' }}>
                            {/* Search Bar */}
                            <input
                                style={{ height: '6vh', width: '80%' }}
                                type="text"
                                placeholder="Search conditions..."
                                value={searchTerm}
                                className="database-page-search-bar"
                                onChange={(e) => handleSearch(e.target.value)}
                            />

                            {/* Filters */}
                            <div className="database-page-filters" style={{ marginLeft: '10px', marginTop:'10px'}}>
                                <button
                                className="database-page-filter-button"
                                onClick={showAddConditionPopup}
                                style={{ height: '6vh' }}
                                >
                                Add Condition
                                </button>
                            </div>
                            </div>


                            <label style={{marginLeft:'1vw', marginBottom:'1vw'}}>{`Number of results: ${filteredConditions.length}`}</label>
                            {/* MEdications List */}
                        <ul className="condition-list">
                            {filteredConditions.map((condition, index) => (
                                <li
                                key={condition.conditionId}
                                className="condition-item"
                                onClick={() => editCondition(index)}
                                >
                                <div className="condition-card">
                                    <div className="condition-header">
                                    <h3>{condition.conditionName}</h3>
                                    </div>
                                    <div className="condition-body">
                                    <p className="condition-description">
                                        {condition.description || "No description available."}
                                    </p>
                                    {condition.symptoms.length > 0 ? (
                                        <ul className="condition-symptoms">
                                        {condition.symptoms.slice(0, 3).map((symptom) => (
                                            <li key={symptom.id} className="symptom-item">
                                            {symptom.label}
                                            </li>
                                        ))}
                                        {condition.symptoms.length > 3 && (
                                            <li className="symptom-more">and more...</li>
                                        )}
                                        </ul>
                                    ) : (
                                        <p className="no-symptoms">No symptoms listed.</p>
                                    )}
                                    </div>
                                </div>
                                </li>
                            ))}
                            </ul>

                    </div>
                    }
                </div>

            )}
        </div>
    );
};

export default DatabasePageLoggedInView;
