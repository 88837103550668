import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/HomePage.css';
import '../styles/SessionReview.css'
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Slider } from "@mui/material";
import { fa0, fa1, fa2, fa3, fa4, fa5, faArrowLeft, faBarsProgress, faBicycle, faBorderNone, faCalendarDays, faCheck, faCircle, faCircleCheck, faCross, faDumbbell, faNotdef, faRemove, faRotateRight, faRunning, faSwimmer } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import colorPalette from "../utils/colors";

import conditionsList from "../localdata/conditionswithmedications";
import { secondsToTimeString } from "../utility";
import Spinner from "./Spinner";
import { Plan } from "../models/plan";
import { generateAIPlan } from "../utils/WorkoutGenerator";
import ChartComponent from "./ChartComponent";

interface Medication{
    name:string,
    code:string,
    side_effects:Array<string>
}

interface Condition{
    name:string,
    symptoms:Array<string>,
    medications:Array<Medication>
}

interface CheckedCondition{ condition: Condition; checked: boolean }

interface CheckedMedication{ medication: Medication; checked: boolean }

interface HomePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onInfoSet: (user: User) => void,

}

const HomePageLoggedInView = ({user, onLogoutSuccessful, onInfoSet}:  HomePageLoggedInProps) => {
    const [questionStage, setQuestionStage] = useState<number>(0);

    const [currentUser, setCurrentUser] = useState<User>(user);

    const [height, setHeight] = useState(180);
    const [weight, setWeight] = useState(80);
    const [waist, setWaist] = useState(34);
    // const [conditions, setConditions] = useState([]);
    const [medications, setMedications] = useState([]);
    const [checkedConditions, setCheckedConditions] = useState<Array<CheckedCondition>>(
    conditionsList.map((condition) => ({ condition, checked: false }))
    );  
    const [checkedMedications, setCheckedMedications] = useState<CheckedMedication[]>(
        conditionsList.flatMap(condition => condition.medications.map(medication => ({
          medication,
          checked: false
        }))));
      
    const [checkedActivities, setCheckedActivities] = useState<Array<number>>([0]);
    const [checkedAvailability, setCheckedAvailability] = useState<number>(1);
    const [checkedLevel, setCheckedLevel] = useState<number>(1);

    const [bestEfforts5k, setBestEffort5k] = useState(-1);
    const [bestEfforts40k, setBestEffort40k] = useState(-1);
    const [bestEfforts100m, setBestEffort100m] = useState(-1);

    const [generatingPlan, setGeneratingPlan]= useState(false);
    const [generatedPlan, setGeneratedPlan]= useState(false);

    const [plans, setPlans] = useState<Array<Plan>>([]);

    const handleActivityClick = (id: number) => {
        setCheckedActivities((prevCheckedActivities) => {
            if (prevCheckedActivities.includes(id)) {
                // Remove id if it exists
                return prevCheckedActivities.filter(activityId => activityId !== id);
            } else {
                // Add id if it doesn't exist
                return [...prevCheckedActivities, id];
            }
        });
    };
    
    useEffect(() => {
        getPlans();
    }, []);

    const handleCheckboxChange = (name: string) => {
        setCheckedConditions((prevCheckedConditions) =>
          prevCheckedConditions.map((item) =>
            item.condition.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };

      const handleMedicationCheckboxChange = (code: string) => {
        setCheckedMedications((prevCheckedMedications) =>
            prevCheckedMedications.map((item) =>
            item.medication.code === code ? { ...item, checked: !item.checked } : item
          )
        );
      };
    // State variables to store the answers and form stage
    const [formData, setFormData] = useState({
        heartCondition: '',
        chestPainActivity: '',
        chestPainRest: '',
        dizziness: '',
        boneJointProblem: '',
        bloodPressureMedication: '',
        dob: '',
        address: '',
        signature: '',
    });

    const disclaimerText = "I hereby declare that by signing this form I accept sole responsibility for my health throughout any training plan or coaching undertaken. Training undertaken from this plan is done so solely at my own risk. I declare that I will not train beyond my ability and I understand that any information or advice given does not substitute, replace, or change any professional medical advice, diagnosis, or treatment. If you believe you are injured or require health-related advice, you should contact a health-care professional.I hereby fully and forever release and discharge the online personal trainer (seller), its assigns and agents from all claims, demands, damages, rights of action, present and future therein. I understand and warrant, release and agree that I am in good physical condition and that I have no disability, impairment or ailment preventing me from engaging in active or passive exercise that will be detrimental to overall health, safety, or comfort, or physical condition if I engage or participate (other than those items fully discussed on the health history form). All client information received will be destroyed upon finishing or termination of a training plan or package. Any information stored will be done so securely and safely in line with GDPR guidelines.  Any information given shall not be shared with any 3rd party companies or otherwise. All information given is tailored and private to the purchaser of this plan and by signing this you declare that any information shall not be distributed outside of it’s intended users. I understand that within 14 days of the first month purchase, the client is entitled to a full refund. After this point fees are non-refundable for the month purchased."

    const nextStage = () => setQuestionStage(prevStage => prevStage + 1);
    const prevStage = () => setQuestionStage(prevStage => Math.max(prevStage - 1, 0));

    async function logout() {
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setQuestionStage(0);
    };
    async function getPlans(){
        const response = await Api.getPlans().then(res=>{
            setPlans(res);
        });
    }

    const finishReview = () => {
        setQuestionStage(0);
    }

    const getActivityTypeFromIndex = (i:number)=>{
        switch (i){
            case 0:
                return 'Run';
            case 1:
                return 'Cycle';
            case 2:
                return 'Swim';
            case 3:
                return 'Strength';
            default:
                return 'Run'
        }
    }

    function generateAIPlanAsync(user:User, level:number, activities:Array<string>, numberOfWeeks:number, conditions:Array<CheckedCondition>, medications:Array<CheckedMedication>): Promise<Plan> {
        return new Promise((resolve) => {
            const newPlan: Plan = generateAIPlan(user, level, activities, numberOfWeeks, conditions, medications);
            resolve(newPlan);
            setGeneratedPlan(true);
        });
    }
    
    async function handleSubmitClick() {    
        setGeneratingPlan(true)
        if (checkedConditions.filter(c=>c.checked === true).length <= 0){
            try {
                const newUser = { ...user };
        
                // Update user information
                newUser.info = {
                    height: height,
                    weight: weight,
                    waistCircumference: waist,
                    conditions: checkedConditions,
                    medications: checkedMedications,
                    parq: formData,
                    complete: true,
                };
        
                newUser.bestEfforts[0].time = secondsToTimeString(bestEfforts5k);
                newUser.bestEfforts[4].time = bestEfforts40k.toString();
                newUser.bestEfforts[5].time = secondsToTimeString(bestEfforts100m);
            
                // Use await to handle asynchronous plan generation
                const newPlan: Plan = await generateAIPlanAsync(newUser, checkedLevel, checkedActivities.map(i => getActivityTypeFromIndex(i)), 8, newUser.info.conditions, newUser.info.medications);
                newUser.currentPlan = newPlan;
                await Api.updateUser(newUser._id, newUser);
                setCurrentUser(newUser);
                setGeneratingPlan(false);
                setGeneratedPlan(true);
            } catch (error) {
                console.error('Error updating user:', error);
                alert('Failed to save changes');
            }
        }else{
            if (plans){
                try {
                    const newUser = { ...user };
            
                    // Update user information
                    newUser.info = {
                        height: height,
                        weight: weight,
                        waistCircumference: waist,
                        conditions: checkedConditions,
                        medications: checkedMedications,
                        parq: formData,
                        complete: true,
                    };
            
                    newUser.bestEfforts[0].time = secondsToTimeString(bestEfforts5k);
                    newUser.bestEfforts[4].time = bestEfforts40k.toString();
                    newUser.bestEfforts[5].time = secondsToTimeString(bestEfforts100m);
                
                    // Find first condition
                    const firstCondition = checkedConditions.filter(c=>c.checked)[0].condition.name.toLowerCase()
                    const foundPlan = plans.find(p=>p.title.toLowerCase().includes(firstCondition));
                    if (firstCondition && foundPlan){
                        // Use await to handle asynchronous plan generation
                        const newPlan: Plan = foundPlan
                        newUser.currentPlan = newPlan;
                        await Api.updateUser(newUser._id, newUser);
                        setCurrentUser(newUser);
                        setGeneratingPlan(false);
                        setGeneratedPlan(true);
                    }else{
                        alert("Couldn't Find a Suitable Plan");
                        setCurrentUser(newUser);
                        setGeneratingPlan(false);
                        setGeneratedPlan(true);
                    }

                } catch (error) {
                    console.error('Error updating user:', error);
                    alert('Failed to save changes');
                }
            }

        }

    }

    async function regeneratePlan(){
        const newUser = { ...user };

        // Update user information
        newUser.info = {
            height: height,
            weight: weight,
            waistCircumference: waist,
            conditions: checkedConditions,
            medications: checkedMedications,
            parq: formData,
            complete: true,
        };

        newUser.bestEfforts[0].time = secondsToTimeString(bestEfforts5k);
        newUser.bestEfforts[4].time = bestEfforts40k.toString();
        newUser.bestEfforts[5].time = secondsToTimeString(bestEfforts100m);

        // Use await to handle asynchronous plan generation
        const newPlan: Plan = await generateAIPlanAsync(newUser, checkedLevel, checkedActivities.map(i => getActivityTypeFromIndex(i)), 8, newUser.info.conditions, newUser.info.medications);
        newUser.currentPlan = newPlan;
        // await Api.updateUser(newUser._id, newUser);
        setCurrentUser(newUser);
        console.log(newPlan)
    }
    
    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to an API or display it
        console.log(formData);
    };

    const marks = generateMarks();

    const sliderStyling = {
        color: 'gray', // or use a hex code like '#1976d2'
        height: 48, // height of the slider track
        width: '60vw',
        borderRadius:'1px',
        margin:'5px 20px',
        '& .MuiSlider-thumb': {
            height: 48, // size of the thumb
            width: 6,
            backgroundColor: '#fff',
            borderRadius:'0px',
            // border: '2px solid currentColor',
            '&:hover': {
                boxShadow: 0,
                backgroundColor:'steelblue'

            },
            '&.Mui-active': {
                boxShadow: 0,
                backgroundColor:'steelblue'
            },
        },
        '& .MuiSlider-rail': {
            height: 48,
        },
        '& .MuiSlider-track': {
            height: 48,
        },
    }
    return (
        user.info ? 
        <div className="home-page" style={{display:'flex', height:'70vh', justifyContent:'center'}}>
            <h2 style={{fontSize:'3.5em', color:'#454545'}} className="main-title">{`Welcome back ${user.fullname.split(' ')[0]}!`}</h2>
            <p style={{color:'#454545'}}>Ready to log another activity?</p>
            <div className="review-button" style={{display:'flex', width:'auto', marginTop:'0', height:'auto',flexDirection:'column', justifyContent:'center', alignContent:'center', alignItems:'center', justifyItems:'center'}}>
                <Link to={'/dashboard'} >Go To Dashboard</Link>
            </div>
        </div>
            :
            questionStage === 0 ?
            <div className="home-page" style={{display:'flex', height:'70vh', flex:1,justifyContent:'center', alignItems:'center'}}>
                    <h2 style={{fontSize:'3.5em', color:'#454545', fontWeight:'bold', fontStyle:'italic'}} className="main-title">Hi There!</h2>
                    <p style={{color:'#454545'}}>Fill out some basic information to get started</p>
                    <label className="review-button" style={{marginTop:'0'}} onClick={()=>setQuestionStage(1)}>Start Your Journey With Vitova!</label>
                </div>
            :
            (
            <body style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center', height:'80vh'}}>
                <div 
                style={{
                    backgroundColor: '#ffffff55', 
                    height: '80vh', 
                    width: '90vw', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start', 
                    borderRadius:'5px',
                    alignItems: 'center',
                    color:'white',
                    paddingTop:'20px'
                }}>

                    {questionStage === 1 && 
                    <div style={{width:'100%'}}>
                        <FontAwesomeIcon className="back-button" style={{marginLeft:'1.5vw'}} onClick={()=>setQuestionStage(0)} icon={faArrowLeft}></FontAwesomeIcon>

                    </div>
                    }
                    <div >
                        {questionStage === 1 && (
                            <div className="form-stage">
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Terms and Conditions</h2>
                                
                                <label style={{fontSize:'1vw', textAlign:'center', padding:'30px 20px', backgroundColor:`white`, borderRadius:'5px', color:'#454545'}} >{disclaimerText}</label>

                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-next-button" onClick={nextStage}>Accept Conditions</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 2 && (
                            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                                <div style={{width:'auto', display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh'}}>
                                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="main-title">Basic Information</h2>
                                    <label style={{textAlign:'center', color:'#454545'}}>Let's get the basics done first!</label>

                                    <div className="dob-container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <label className="question-label">
                                            What is your date of birth?
                                        </label>
                                        <input className="question-input"
                                            style={{fontSize: '1.2em', padding: '10px 15px', width: '96%', marginTop:'1vh',textAlign: 'center', border: '1px solid #ccc', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}} 
                                            type="date" 
                                            name="dob" 
                                            value={formData.dob} 
                                            onChange={handleChange} />
                                    </div>

                                    <label className="question-label" style={{color:'#454545'}}>What Is Your Height? (cm)</label>
                                    <Slider 
                                        style={{marginTop:'0'}}
                                        defaultValue={180}
                                        value={height}
                                        onChange={(e: Event, value: number | number[]) => setHeight(value as number)}
                                        step={1} 
                                        min={150} 
                                        max={220}
                                        // marks
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    <label className="question-label"style={{color:'#454545'}}>What Is Your Weight? (kg)</label>
                                    <Slider
                                        style={{marginTop:'0'}}
                                        value={weight}
                                        defaultValue={80}
                                        onChange={(e: Event, value: number | number[]) => setWeight(value as number)}
                                        step={1}
                                        min={40}
                                        max={200}
                                        // marks={marks}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    <label className="question-label"style={{color:'#454545'}}>What Is Your Waist Circumference? (in)</label>
                                    <Slider
                                        style={{marginTop:'0'}}
                                        defaultValue={34}
                                        value={waist}
                                        onChange={(e: Event, value: number | number[]) => setWaist(value as number)}
                                        step={1}
                                        min={20}
                                        max={70}
                                        // marks={marks}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 3 && (
                            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll', color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Health Questions</h2>
                                <label >Let's learn about your health</label>

                                {/* Heart Condition */}
                                <label className="question-label">
                                    Has your doctor ever said you have a heart condition and that you should only do physical activity recommended by a doctor?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="heartCondition" 
                                            value="yes" 
                                            checked={formData.heartCondition === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="heartCondition" 
                                            value="no" 
                                            checked={formData.heartCondition === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                {/* Chest Pain Activity */}
                                <label className="question-label">
                                    Do you feel pain in your chest when you do physical activity?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="chestPainActivity" 
                                            value="yes" 
                                            checked={formData.chestPainActivity === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="chestPainActivity" 
                                            value="no" 
                                            checked={formData.chestPainActivity === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                {/* Chest Pain Rest */}
                                <label className="question-label">
                                    In the past month, have you had chest pain when you were not doing physical activity?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="chestPainRest" 
                                            value="yes" 
                                            checked={formData.chestPainRest === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="chestPainRest" 
                                            value="no" 
                                            checked={formData.chestPainRest === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                {/* Dizziness */}
                                <label className="question-label">
                                    Do you lose balance because of dizziness or do you ever lose consciousness?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="dizziness" 
                                            value="yes" 
                                            checked={formData.dizziness === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="dizziness" 
                                            value="no" 
                                            checked={formData.dizziness === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                {/* Joints */}
                                <label className="question-label">
                                    Do you have a bone or joint problem (e.g., back, knee, or hip) that could be made worse by a change in your physical activity?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="boneJointProblem" 
                                            value="yes" 
                                            checked={formData.boneJointProblem === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="boneJointProblem" 
                                            value="no" 
                                            checked={formData.boneJointProblem === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                {/* Blood Pressure */}
                                <label className="question-label">
                                    Is your doctor currently prescribing medication for your blood pressure or heart condition?
                                </label>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label className="radio-label">
                                        <input className="radio-yes" 
                                            type="radio" 
                                            name="bloodPressureMedication" 
                                            value="yes" 
                                            checked={formData.bloodPressureMedication === 'yes'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        Yes
                                    </label>
                                    <label className="radio-label">
                                        <input className="radio-no" 
                                            type="radio" 
                                            name="bloodPressureMedication" 
                                            value="no" 
                                            checked={formData.bloodPressureMedication === 'no'} 
                                            onChange={handleChange} />
                                        <span className="radio-custom"></span>
                                        No
                                    </label>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}   
                        {questionStage === 4 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Conditions</h2>  
                                <label style={{marginBottom:'3vh', }}>Which of the following conditions do you suffer from?</label>
                                <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                    {checkedConditions.map((condition:CheckedCondition, index) => (
                                        <div key={index} style={{ width: '33%', display: 'flex', alignItems: 'center'}}>
                                        <Checkbox 
                                            checked={condition.checked}
                                            onChange={() => handleCheckboxChange(condition.condition.name)}
                                            style={{color:'#454545'}}/>
                                        <label>{condition.condition.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}      
                        {questionStage === 5 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                            <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Medications</h2>  
                            <label style={{marginBottom:'3vh', }}>Which of the following medications are you taking?</label>
                            <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                                <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                {checkedMedications.map((medication:CheckedMedication, index) => (
                                        <div key={index} style={{ width: '25%', marginLeft:'2vw', marginRight:'2vw',display: 'flex', alignItems: 'center'}}>
                                        <Checkbox 
                                            checked={medication.checked}
                                            onChange={() => handleMedicationCheckboxChange(medication.medication.code)}
                                            style={{color:'#454545'}}/>
                                        <label>{medication.medication.name}</label>
                                        </div>
                                ))}
                                </div>
                            </div>
                            <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                            </div>
                        </div>
                        )}   
                        {questionStage === 6 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Current Activities</h2>
                                {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                                <label style={{marginBottom:'1vh'}}>Let us know what activities you enjoy so that we can create a suitable plan for you!</label>
                                <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                                <ul className="activity-grid-container">
                                    {[
                                        { id: 0, title: "Running", icon: faRunning },
                                        { id: 1, title: "Cycling", icon: faBicycle },
                                        { id: 2, title: "Swimming", icon: faSwimmer },
                                        { id: 3, title: "Strength and Conditioning", icon: faDumbbell },
                                    ].map((activity) => (
                                        <li key={activity.id} className="activity-grid-item" onClick={()=>handleActivityClick(activity.id)}>
                                            
                                            <div className="activity-card">
                                                {checkedActivities.includes(activity.id) ?
                                                    <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                                    :
                                                    <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                                                }


                                                <FontAwesomeIcon className="activity-card-img" icon={activity.icon} />
                                                <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1.5vw'}}>{activity.title.replace('Running', 'Running/Walking')}</h2>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}   
                        {questionStage === 7 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Current Availability</h2>
                                {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                                <label style={{marginBottom:'1vh'}}>Let's learn about your current training availability! Which best describes you?</label>
                                {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}

                                <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                                <ul className="activity-grid-container">
                                    {[
                                        { id: 0, title: "I can train once a week", icon: fa0 },
                                        { id: 1, title: "I can train once or twice a week", icon: fa1 },
                                        { id: 2, title: "I can train 3 to 4 times a week", icon: fa2 },
                                        { id: 3, title: "I can train 5 to 7 times a week", icon: fa3 },
                                    ].map((option) => (
                                        <li key={option.id} className="activity-grid-item" onClick={()=>setCheckedAvailability(option.id)}>
                                            
                                            <div className="activity-card">
                                                {checkedAvailability === option.id ?
                                                    <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                                    :
                                                    <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                                                }


                                                <FontAwesomeIcon className="activity-card-img" icon={option.icon} />
                                                <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1.5vw'}}>{option.title}</h2>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 8 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Current Level</h2>
                                {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                                <label style={{marginBottom:'1vh'}}>Let's learn about your current training level! Which best describes you?</label>
                                {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}

                                <div style={{display:'flex', flexDirection:'column', overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                                <ul className="activity-grid-container">
                                    {[
                                        { id: 0, title: "I'm a beginner looking to build consistency", icon: fa0 },
                                        { id: 1, title: "I'm an intermediate looking to improve my fitness", icon: fa1 },
                                        { id: 2, title: "I'm an advanced athlete looking to take it to the next level", icon: fa2 },
                                        { id: 3, title: "I'm an elite athlete looking to optimise my training", icon: fa3 },
                                    ].map((option) => (
                                        <li key={option.id} className="activity-grid-item" onClick={()=>setCheckedLevel(option.id)}>
                                            
                                            <div className="activity-card">
                                                {checkedLevel === option.id ?
                                                    <FontAwesomeIcon icon={faCircleCheck} className="activity-card-checkbox" style={{color:'steelblue'}}/>
                                                    :
                                                    <FontAwesomeIcon icon={faCircle} className="activity-card-checkbox" style={{color:'#676767'}}/>
                                                }


                                                <FontAwesomeIcon className="activity-card-img" icon={option.icon} />
                                                <h2 className="activity-card-title" style={{color:'#676767', fontSize:'1vw'}}>{option.title}</h2>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}   
                        {questionStage === 9 && (
                            <div className="form-stage" style={{color:'#454545'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'#454545'}} className="parq-title">Best Efforts</h2>
                                {/* <label style={{marginBottom:'1vh', }}>Let us know your goals so that we can <b>adapt</b> your plans <b>optimally</b> so you can <b>reach your potential</b>!</label> */}
                                <label style={{marginBottom:'1vh'}}>Let's learn about your best efforts!</label>
                                {/* <label style={{marginBottom:'1vh'}}>How many times a week do you train?</label> */}

                                <div style={{display:'flex', flexDirection:'column',overflow:'scroll', maxHeight:'60vh', padding:'25px'}}>
                                {checkedActivities.includes(0) &&
                                <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                                <label className="question-label" style={{color:'#454545'}}>What Is Your 5K Run Personal Best?</label>
                                    <Slider 
                                        style={{marginTop:'0'}}
                                        defaultValue={25 * 60}
                                        value={bestEfforts5k}
                                        onChange={(e: Event, value: number | number[]) => setBestEffort5k(value as number)}
                                        step={2} 
                                        min={12 * 60} 
                                        max={60 * 60}
                                        valueLabelFormat={(l)=>secondsToTimeString(l)}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    </div>
                                }
                                {checkedActivities.includes(1) &&
                                <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                                <label className="question-label" style={{color:'#454545'}}>What Is Average Power 40K Cycle Personal Best? (Watts)</label>
                                    <Slider 
                                        style={{marginTop:'0'}}
                                        defaultValue={150}
                                        value={bestEfforts40k}
                                        onChange={(e: Event, value: number | number[]) => setBestEffort40k(value as number)}
                                        step={10} 
                                        min={50} 
                                        max={600}
                                        // valueLabelFormat={(l)=>secondsToTimeString(l)}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    </div>
                                }
                                {checkedActivities.includes(2) &&
                                <div style={{display:'flex', justifyContent:'center',flexDirection:'column'}}>
                                <label className="question-label" style={{color:'#454545'}}>What Is 100M Swim Personal Best?</label>
                                    <Slider 
                                        style={{marginTop:'0'}}
                                        defaultValue={120}
                                        value={bestEfforts100m}
                                        onChange={(e: Event, value: number | number[]) => setBestEffort100m(value as number)}
                                        step={1} 
                                        min={45} 
                                        max={60 * 4}
                                        valueLabelFormat={(l)=>secondsToTimeString(l)}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    </div>
                                    }
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}           
                        {questionStage === 10 && (
                                generatingPlan ?
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>
                                    <Spinner text="Generating Your Custom Plan!"/>
                                </div>
                                :
                                generatedPlan ?
                                // <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'center', alignItems:'center', height:'100vh'}}>

                                // <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'40%', textAlign:'center'}}>
                                //     <div onClick={()=>onInfoSet(currentUser)} className="top-panel-streak">
                                //         <FontAwesomeIcon className="streak-icon" icon={faCalendarDays}></FontAwesomeIcon>
                                //         <label className="streak-label">FTP Running Plan</label>
                                //     </div>
                                //     <label style={{ fontSize: '1.5vw', fontWeight: 'bold', marginTop:'2vh', color:'#454545'}}>Your Plan Is Ready!</label>

                                //     {/* <Link
                                //     to={'/dashboard'}
                                //     className="review-button" style={{padding:'8px 25px', fontSize:'1.25vw', width:'auto', marginTop:'2vh',}} onClick={finishReview}>View My Plan!
                                //     </Link> */}
                                // </div>
                                // </div>
                                <div className='card-enlarged' style={{backgroundColor:'#ffffff66', height:'75vh', width:'100%', alignItems:'flex-start', flexDirection:'row',}}>        
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:`${colorPalette.default}88`, width:'100%', height:'8vh', borderRadius:'0'}}>
                                    <div style={{backgroundColor:'transparent',  display:'flex', alignItems:'center', width:'100%'}}>
                                        {/* <FontAwesomeIcon className="back-button" style={{width:'2vw', height:'2vw', marginLeft:'0.75vw', padding:'5px'}} onClick={()=>regeneratePlan()} icon={faRotateRight}></FontAwesomeIcon> */}
                                        {/* <label style={{fontWeight:'bold', fontSize:'1.3vw', marginLeft:'8px'}}>Generate A New Plan</label> */}
                                    </div>
                                </div>
                                <div style={{display:'flex', width:'100%', height:'90%', flexDirection:'row', alignItems:'flex-start'}}>
        
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:1, backgroundColor:'#f1f1f1', flexDirection:'column'}}>
                                            <label style={{color:'#565656', marginTop: '10px', padding:'5px 20px', fontSize:'1.5vw', fontWeight:'bold'}}>{currentUser.currentPlan.title}</label>
                                            <label style={{color:'#565656', fontSize:'1.125vw', textAlign:'justify', padding:'0px 20px',}}>{currentUser.currentPlan.description.slice(0, 400)}</label>
        
                                            <div style={{display: 'flex', marginTop:'1vh',margin:'10px 20px',alignItems: 'flex-start', flexDirection:'column', justifyContent:'flex-start'}}>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop: '5px', color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentPlan.content.length} Weeks`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop: '5px',color: '#fff', fontWeight: 'bold'}}>
                                            {`${currentUser.currentPlan.weeklyAdjustmentValue * 100}% Per Week`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}dd`, padding: '6px 10px',width:'100%', borderRadius: '4px', marginTop:'5px',color: '#fff', fontWeight: 'bold'}}>
                                            {currentUser.currentPlan.baseLoad <= 200 ? `Beginner`:currentUser.currentPlan.baseLoad > 200 && currentUser.currentPlan.baseLoad <= 400 ? `Intermediate`: `Advanced`}
                                        </div>
                                        <div style={{backgroundColor: `${colorPalette.steelBlue}`, padding: '6px 10px', width:'100%',borderRadius: '4px', marginTop:'5px',color: '#fff', fontWeight: 'bold'}}>
                                            {currentUser.currentPlan.weeklyAdjustmentValue < 0.025 ? `Steady Improvement`:currentUser.currentPlan.weeklyAdjustmentValue > 0.025 && currentUser.currentPlan.weeklyAdjustmentValue <= 0.05 ? `Standard Improvement`: `Fast Improvement`}
                                        </div>
                                    </div>
                                    <button className="set-plan-button" 
                                                style={{
                                                    fontWeight: 'bold', 
                                                    color: '#fff', 
                                                    padding: '10px 15px', 
                                                    borderRadius: '5px', 
                                                    border: 'none', 
                                                    cursor: 'pointer', 
                                                    width: 'auto',
                                                    transition: 'background-color 0.3s',
                                                    margin:'0px 20px',
                                                }}
                                                onClick={() => {
                                                    onInfoSet(currentUser); // Call onInfoSet with currentUser
                                                }}
                                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = `${colorPalette.lightSeaGreen}dd`}
                                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = colorPalette.dodgerBlue}
                                        >
                                            {`Go To Dashboard`}
                                        </button>
                                        </div>
                                        <div style={{display:'flex', width:'100%', height:'100%', flex:2, flexDirection:'column', alignItems:'flex-start', justifyContent:'center'}}>
                                            <label style={{color:'#565656', marginTop: '10px', marginLeft:'1vw', fontSize:'1.5vw', fontWeight:'bold'}}>Week 1 Training Load Overview</label>
                                            <ChartComponent mh={80} mw={100} weekData={currentUser.currentPlan.content[0].days} user={currentUser}></ChartComponent>
                                        </div>
                                    </div>
                            </div>
                                :
                                checkedConditions.filter(c=>c.checked === true).length <= 0 ?
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish And Generate My Plan!</Button>
                                </div>
                                </div>
                                :
                                <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll', color:'#454545', display:'flex', justifyContent:'flex-start', alignItems:'center', height:'100vh'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'#454545'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            {checkedConditions.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                    <ul>
                                                        {checkedConditions.map((c, index) => (
                                                            c.checked &&
                                                            <li key={index}>{c.condition.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedConditions.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length > 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                    <ul>
                                                        {checkedMedications.map((m, index) => (
                                                            m.checked &&
                                                            <li key={index}>{m.medication.name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                            }
                                            {checkedMedications.filter(c=>c.checked).length <= 0 && 
                                                <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>None</td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                                
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Find Me My Plan!</Button>
                                </div>
                                </div>
                        )}
                    </div>
                </div>
            </body>
            ));

}

export default HomePageLoggedInView;